<template>
  <div id="customers-events">
    <v-row class="mb-5" id="customers">
      <v-col cols="12" sm="12">
        <div class="display-1 mb-5 flex justify-between">
          Prospect Activation
          <v-btn
            @click="$router.go(-1)"
            class="float-right btns color-white"
            color="#109CF1"
            elevation="0"
            >Back</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <span class="inline-flex items-center justify-center p-2 text-xs font-weight-bold leading-none  border border-1 rounded-full text-white activatedin" :class="activatedIn.approach">{{activatedIn.title}}</span>
     <v-row v-if="loading.table">
      <v-col cols="12" sm="12" class="h-screen">
        <v-progress-circular
          id="sharethis-loading"
          :size="50"
          color="#94D227"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12" sm="12">
        <v-data-table
          show-select
          @input="enterSelect($event)"
          item-key="estid"
          v-model="selected"
          :single-select="singleSelect"
          :headers="table__header"
          :items="table__data"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1 mt-10"
          @page-count="pageCount = $event"
        ></v-data-table>
        <v-pagination
          id="customers-pagination"
          :total-visible="7"
          v-model="page"
          :length="pageCount"
        ></v-pagination>
      </v-col>
      <v-col cols="12">
        <div class="display-6 font-weight-bold mb-5">Personalized subject</div>
        <v-text-field
          label="Subject"
          outlined
          v-model="mail.subject"
        ></v-text-field>
        <div class="display-6 font-weight-bold mb-5">Personalized message</div>
        <vue-editor v-model="mail.message" />
      </v-col>
      <v-col cols="12">
        <v-btn
          :disabled="sendButton"
          class="float-right btns"
          color="#109CF1"
          elevation="0"
          @click="sendMail()"
          >Send Emails <v-icon class="ml-2">mdi-send</v-icon></v-btn
        >
      </v-col>
    </v-row>
    <v-snackbar :color="snackbar.color" v-model="snackbar.state" :top="true">
      {{ snackbar.msg }}
      <template v-slot:action="{ attrs }">
        <v-btn color="indigo" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  name: "custmers-events",
  components: { VueEditor },
  data() {
    return {
      activatedIn:this.$store.getters.getActivatedIn,
      loading: {
        table: true
      },
      selected__cat:this.$store.getters.getSelectedCat,
      snackbar: {
        state: false,
        msg: "",
        color:'red'
      },
      mail: {
        subject: "",
        message: "",
      },
      sendButton: true,
      singleSelect: false,
      selected: [],
      page: 1,
      pageCount: 0,
      customers_events: [],
      itemsPerPage: 5,
      table__header: [],
      table__data: [],
    };
  },
  created() {
    // if (this.$store.getters.getStepper.sharethis < 4) {
    //   this.$router.go(-1)
    // }
  },
  mounted() {
    this.get_customers();
  },
  methods: {
    async get_customers() {
      this.selected__cat =this.selected__cat ? this.selected__cat :"Swimwear"
      await this.$http
        .post("/sharethis_activated_prospects?category_name="+this.selected__cat)
        .then((res) => {
          console.log("--/sharethis_activated_prospects", res.data);
          this.format__data(res.data.prospects);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    sendMail() {
      this.snackbar.state = true;
      if (this.mail.subject == "" || this.mail.message == "") {
        this.snackbar.msg = "Make sure to enter subject and message !!!";
      } else {
        this.snackbar.msg = "Your mail has been sent successfully !!!";
        this.snackbar.color='green'
      }
    },
    enterSelect(values) {
      if (values.length >= 1) {
        this.sendButton = false;
      } else {
        this.sendButton = true;
      }
    },
    format__data(customers_events) {
      var keys = Object.keys(customers_events[0]);
      keys.forEach((key) => {
        this.table__header.push({ text: key, value: key });
      });
      customers_events.forEach((el) => {
        this.table__data.push(el);
      });
      this.loading={
        table: false
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#customers-events::v-deep {
  .activatedin.sharethis{
    background: #94d227 !important;
  }
  .activatedin.crm{
    background: #109cf1 !important;
  }
  .btns * {
    color: white;
  }
  .v-data-table-header {
    background: #009fff42 !important;
  }
  #customers-pagination .theme--light.v-pagination .v-pagination__item--active {
    background-color: #ffffff !important;
    border-color: #109cf1 !important;
    color: #109cf1 !important;
  }
  #customers-pagination button {
    box-shadow: none !important;
    border: 1px solid #109cf1 !important;
  }
  #customers-pagination
    button[aria-label="Next page"]:not(button[disabled="disabled"])
    *,
  #customers-pagination
    button[aria-label="Previous page"]:not(button[disabled="disabled"])
    * {
    color: white;
  }
  #customers-pagination
    button[aria-label="Next page"]:not(button[disabled="disabled"]),
  #customers-pagination
    button[aria-label="Previous page"]:not(button[disabled="disabled"]) {
    background: #109cf1;
  }
  #customers-pagination {
    position: absolute !important;
    top: 82px;
    right: 0;
  }
  #customers {
    position: relative !important;
  }
}
</style>